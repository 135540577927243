<template>
  <tr
    v-for="item in data"
    :key="item.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
        <p v-if="item?.user?.profile?.first_name">
          {{ item?.user?.profile?.first_name }}
          {{ item?.user?.profile?.last_name }}
        </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.hiring_request.job_title">
        {{ item?.hiring_request?.job_title }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.hiring_request.reporting_to">
        {{ item?.hiring_request?.reporting_to }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.practice.practice_name">
        {{ item?.practice?.practice_name }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.created_at">{{ convertDate(item.created_at) }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.status === 0">DECLINED</p>
      <p v-if="item.status === 1">ACCEPTED</p>
      <p v-if="item.status === 2">MADE</p>
      <p v-if="item.status === 3">REVISED</p>
      <p v-if="item.status === 4">PENDING</p>
      <p v-if="item.status === 5">DISCARDED</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item?.amount">£ {{ item?.amount }}/hr</p>
    </td>

    <td
      class="
        p-3
        px-5
        cursor-pointer
        hover:scale-110
        transform-gpu
        transition
        duration-100
        ease-linear
      "
    >
      <router-link
        :to="{
          name: link_2,
          params: { [myParameter_2]: item.id },
        }"
      >
        <span class="material-icons"> more_horiz </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    link_2: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    myParameter_2: {
      type: String,
    },
  },
  methods: {
    convertDate(data) {
      return moment(data).format("DD-MM-YYYY");
    },
  },
};
</script>
