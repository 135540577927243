<template>
  <div
    class="flex items-center text-teal mt-3 cursor-pointer space-y-6 py-3 w-24"
    @click="reRoute"
  >
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <div class="flex flex-col justify-center items-center space-y-6 py-3">
    <!-- OPEN OFFERS TITLE -->
    <div class="bg-lightGrey rounded-xl w-11/12 shadow-md">
      <div class="pl-4 py-4">
        <h2 class="text-grey font-bold">OPEN OFFERS</h2>
      </div>
      <!-- <template v-if="!isLoading"> -->
      <template v-if="tableData.length > 0">
        <table class="text-center w-full text-grey my-2">
          <dynamic-table :showTitle="false" title="">
            <candidate-offers-table-item
              :data="getAllOffers"
              :link_1="'hq-employee-details'"
              :myParameter_1="'userId'"
              :link_2="'hq-candidate-offer-ammend'"
              :myParameter_2="'offerId'"
            >
            </candidate-offers-table-item>
          </dynamic-table>
        </table>
        <my-pagination
          :options="options"
          v-model="nullPage"
          :records="10"
          :per-page="10"
          @paginate="myCallback($event)"
        >
        </my-pagination>
      </template>
      <no-data-found v-else />
    </div>
    <!-- <template v-else>
      <table-skeleton />
    </template> -->
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
// import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import candidateOffersTableItem from "@/components/ui/baseComponents/Tables/HQ/candidateOffersTableItem.vue";
export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  components: {
    noDataFound,
    // tableSkeleton,
    dynamicTable,
    candidateOffersTableItem,
  },
  async created() {
    this.loadInterviews();
    // this.newIsLoading = true;
    // this.approvedIsLoading = true;
    // this.declinedIsLoading = true;
    // this.escalatedIsLoading = true;
    // await this.fetchAllHiringRequests(null, this.page);
    // this.newIsLoading = false;
    // await this.fetchAllHiringRequests("approved", this.page);
    // this.approvedIsLoading = false;
    // await this.fetchAllHiringRequests("escalated", this.page);
    // this.escalatedIsLoading = false;
    // await this.fetchAllHiringRequests("declined", this.page);
    // this.declinedIsLoading = false;
  },
  mounted() {
    if (this.$route.query.success) {
      // this.$toast.success(`Application Submitted!`);
    } else if (this.$route.query.error) {
      this.$toast.error(`Something went wrong`);
    }
    this.fetchAllOffers();
  },
  data() {
    return {
      offersData: null,
      offersList: null,
      getAllOffers: null,
      nullPage: 1,
      tableHeaders: [
        "Candidate Name",
        "Job Title",
        "Application Manager",
        "Practice",
        "Offer Date",
        "Offer Status",
        "Offered Amount",
        "View Offer",
      ],
      tableData: [
        {
          id: 1,
          candidate_name: "Peter Parker",
          interview_date: "01.02.2000",
          location: "Head Office Hinckley",
          application_status: "Offer Made",
          role_applied_for: "HQ Manager PA",
          application_manager: "Bill Gates",
        },
        {
          id: 2,
          candidate_name: "Matt Murdock",
          interview_date: "10.12.2001",
          location: "Zoom Meeting",
          application_status: "Accepted",
          role_applied_for: "Receptionist",
          application_manager: "Mark Zuckerberg",
        },
      ],
      options: {
        template: markRaw(customPaginate),
      },
    };
  },
  //   computed: {
  //     getNewApplicationsHiringRequest() {
  //       return this.$store.getters["HQnewHire/getNewApplicationsHiringRequest"];
  //     },
  //   },
  methods: {
    reRoute() {
      this.$router.back();
    },
    async loadInterviews() {
      this.interviewsAreLoading = true;
      try {
        await this.$store.dispatch("HQnewHire/fetchAllInterviews");
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      const data = this.$store.getters["HQnewHire/getAllOffers"];
      this.getAllOffers = data.data;
    },
    async fetchAllOffers() {
      try {
        this.isLoading = true;
        const data = await this.$store.dispatch("HQnewHire/fetchAllOffers");
        console.log("data: ", data?.data?.offers)
        this.offersData = data?.data?.offers;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.error = error.message || "Something went wrong!";
      }
    },
    //   async fetchAllHiringRequests(status, page) {
    //     try {
    //       await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
    //         {
    //           status: status,
    //           page,
    //         },
    //       ]);
    //     } catch (error) {
    //       this.error = error.message || "Something went wrong!";
    //     }
    //   },
    myCallback(e) {
      console.log(e);
    },
  },
};
</script>
